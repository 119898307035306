import React, { useEffect, useState } from 'react';
import Spreadsheet from "react-spreadsheet";
import { getHomeWorkTable } from '../../../services/api';

export default function HtmlLinkErrors({ checkingIndex }) {
    const [table, setTable] = useState([]);
    console.log(checkingIndex);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getHomeWorkTable(checkingIndex);
                const homeworkData = result.data;

                const userIds = [...new Set(homeworkData.map(item => item.user_id))];
                const userNames = [...new Set(homeworkData.map(item => item.username))];

                const header = [{ value: "" }, ...userNames.map(name => ({ value: name }))];
                const formattedData = [header];

                const userWorksMap = {};
                const userErrorsMap = {};
                const userUpdatedMap = {};

                const regex = /data-link-info=\\"([^"]+)\\"/g;

                homeworkData.forEach(item => {
                    const userId = item.user_id;

                    if (!userWorksMap[userId]) {
                        userWorksMap[userId] = [];
                    }

                    if (!userErrorsMap[userId]) {
                        userErrorsMap[userId] = 0;
                    }

                    // Track the latest updated_at date
                    if (!userUpdatedMap[userId] || new Date(item.updated_at) > new Date(userUpdatedMap[userId])) {
                        let updatedDate = new Date(item.updated_at);
                        updatedDate.setHours(updatedDate.getHours() + 6);
                        userUpdatedMap[userId] = updatedDate.toISOString().slice(0, 19).replace('T', ' ');
                    }
                    

                    const resultString = item.result;
                    let match;

                    const uniqueValues = new Set();

                    while ((match = regex.exec(resultString)) !== null) {
                        const decodedValue = match[1].replace(/&quot;/g, '"');

                        try {
                            const jsonValue = JSON.parse(decodedValue);

                            Object.entries(jsonValue).forEach(([key, { view, verified }]) => {
                                if (verified === false) {
                                    uniqueValues.add(view);
                                }
                            });

                        } catch (error) {
                            console.error('Ошибка парсинга JSON:', error);
                        }
                    }

                    if (uniqueValues.size === 0) {
                        uniqueValues.add("Completed");
                    }

                    userWorksMap[userId].push({
                        copy: item.copy,
                        result: [...uniqueValues].join(", ")
                    });

                    if (item.errors) {
                        const errorsCount = parseInt(item.errors, 10) || 0;
                        userErrorsMap[userId] += errorsCount;
                    }
                });

                const maxWorkCount = Math.max(
                    ...Object.values(userWorksMap).map(works => works.length)
                );

                for (let i = 0; i < maxWorkCount; i++) {
                    const rowHtml = [{ value: `Copy ${i + 1}` }];
                    
                    userIds.forEach(userId => {
                        const userWorks = userWorksMap[userId] || [];
                        const workResult = userWorks[i] ? userWorks[i].result : "";

                        rowHtml.push({ value: workResult });
                    });

                    formattedData.push(rowHtml);
                }

                const errorRow = [{ value: "Errors" }];
                userIds.forEach(userId => {
                    const userErrors = userErrorsMap[userId] || 0;
                    errorRow.push({ value: userErrors });
                });
                formattedData.push(errorRow);

                const lastUpdatedRow = [{ value: "Last Updated" }];
                userIds.forEach(userId => {
                    const lastUpdatedDate = userUpdatedMap[userId] || "";
                    lastUpdatedRow.push({ value: lastUpdatedDate });
                });
                formattedData.push(lastUpdatedRow);

                const styledData = formattedData.map((row, rowIndex) =>
                    row.map((cell, cellIndex) => ({
                        ...cell,
                        className: (cellIndex === 0) ? 'results-head' : ''
                    }))
                );

                setTable(styledData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [checkingIndex]);

    return (
        <div style={{ overflowX: 'auto', height: 'calc(100vh - 148px)' }}>
            <Spreadsheet data={table} />
        </div>
    );
}
