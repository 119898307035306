import React, { useState, useEffect } from 'react';
import Links from './results/Links';
import Copies from './results/Copies';
import ResultsInfo from './results/Resultsinfo';
import {
    Button,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getTabsList } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';

export default function Result() {
    const [activeTab, setActiveTab] = useState(0);
    const [tabs, setTabs] = useState([]);
    const { user } = useAuth();

    const settings = useSelector(state => state.settings);
    const tabsResultShow = settings.academy?.tabs_result_show || []; // Массив с видимостью вкладок

    useEffect(() => {
        const fetchTabs = async () => {
            try {
                const fetchedTabs = await getTabsList();
                setTabs(fetchedTabs);
            } catch (error) {
                console.error('Failed to fetch tabs', error);
            }
        };

        fetchTabs();
    }, []);

    const activeButtonStyle = {
        backgroundColor: '#e9eaeb',
    };

    // Проверка, есть ли хотя бы одна активная вкладка
    const hasActiveTabs = tabsResultShow.some(isVisible => isVisible === true);

    return (
        <>
            {/* Если есть активные вкладки */}
            {hasActiveTabs || user.usergroup === 'admin' ? (
    <>
        
        {activeTab === 'info' && (
            <ResultsInfo/>
        )}
        {tabs.length > 0 && (
            tabs.map((tab, index) => (
                (user.usergroup === 'admin' || tabsResultShow[index]) && activeTab === index && (
                    index === 0 ? <Links key={index} /> : <Copies key={index} checkingIndex={index} />
                )
            ))
        )}

        <Button
            key={'info'}
            color="inherit"
            size="medium"
            sx={{ height: '36px', ...(activeTab === 'info' && activeButtonStyle) }}
            onClick={() => setActiveTab('info')}
        >
            Info
        </Button>
        {tabs.map((tab, index) => (
            (user.usergroup === 'admin' || tabsResultShow[index]) && (
                <Button
                    key={tab.name}
                    color="inherit"
                    size="medium"
                    sx={{ height: '36px', ...(activeTab === index && activeButtonStyle) }}
                    onClick={() => setActiveTab(index)}
                >
                    {tab.name}
                </Button>
            )
        ))}
    </>
) : (
    // Если нет активных вкладок, выводим сообщение
    <Typography variant="body1" color="textSecondary">
        Results not available yet.
    </Typography>
)}

        </>
    );
}
